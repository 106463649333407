 

/**************************************************************************************

                                        FONT FAMILY

**************************************************************************************/
  body{
      /* background: linear-gradient(0deg, #aaeaf7 0%, rgb(210, 232, 255),  rgb(193, 202, 255)100%) !important; */
      background: white !important;
  }

@font-face {
    font-family: "Gilroy"; 
    font-style: normal; 
    font-weight: 500;
    src: url("../assets/fonts/Gilroy-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: normal; 
    font-weight: 600;
    src: url("../assets/fonts/Gilroy-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: bold; 
    font-weight: 700;
    src: url("../assets/fonts/Gilroy-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: black; 
    font-weight: 800;
    src: url("../assets/fonts/Gilroy-ExtraBold.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: black; 
    font-weight: 900;
    src: url("../assets/fonts/Gilroy-Black.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: thin; 
    font-weight:300;
    src: url("../assets/fonts/Gilroy-Thin.ttf") format("truetype");
}
@font-face {
    font-family: "Gilroy"; 
    font-style: light; 
    font-weight:100;
    src: url("../assets/fonts/Gilroy-Light.ttf") format("truetype");
}





/**************************************************************************************

                                        GENERAL 

**************************************************************************************/
  
 
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
}
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
a {
    text-decoration: none!important;
    color: #f3f3f3;
    transition: 0.5s all;
}
a:hover {
    color: #7620FF;
}
input:autofill {
    box-shadow:0 0 0 50px #fff inset; /* Change the color to your own background color */
    box-shadow: transparent;
    text-fill-color: #fff;
}
body {
    background: #0e0e1a;
    color: #ffffff;
    font-family: 'Gilroy', sans-serif;
    overflow-x: hidden;
    font-size: 16px;
    font-weight: 400;
}
.container {
    width: 100%;
    margin: 0 auto;
    padding: 0px 30px;
}
@media only screen and (max-width: 859px) {
    .container {
        padding: 0 15px;
    }
}
.regular {
    font-weight: 500!important;
}
.bold {
    font-weight: 700!important;
}
.extraBold {
    font-weight: 900!important;
}
.light {
    font-weight: 300!important;
}
.thin {
    font-weight: 100!important;
}

.btn-primary{
    background: rgb(0, 0, 0);
    width: 100%;
    padding: 15px;
    border: none;
    font-family: 'Gilroy';
    outline: none;
    color: #fff;
    font-size: 13px !important;
    font-weight: 700;
    cursor: pointer;
    border-radius: 0 !important;
    transition: 0.3s all;
}
.btn-primary:hover{
    background-color: black !important;
    transform: scale(1.08);
    box-shadow: 0px 0px 25px rgb(194, 194, 194);
}
.signOutButton{
    margin-left: 15px;
    font-size: 13px;
    transform: 0.4s all;
    display: none;
}
.signOutButton:hover{
    opacity: 0.5;
    color:white;
}
.navButton{
    position:relative;
    padding:12px 25px !important;
    font-size: 14px;
    margin:5px;
    display:inline-block;
    transition: 0.2s all;
}
.navButton::before {
    content:"";
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    border-radius:50px; 
    padding:2px; 
    background: linear-gradient(50deg, rgb(69, 55, 255) 0%, rgb(168, 233, 255) 100%);
    mask: 
       linear-gradient(#fff 0 0) content-box, 
       linear-gradient(#fff 0 0);
    mask-composite: xor; 
            mask-composite: exclude; 
}
.navButton:hover{
    transform: scale(1.01);
    color:white !important;
    box-shadow: 0px 0px 25px rgb(69, 126, 143);
    border-radius: 25px;
}

.sideNav{
    background: black;
    border-width: 3px;
    border-style: solid;
    border-image: linear-gradient( to bottom, #645ad9, rgba(0, 0, 0, 0) ) 1 100%;
    border-width: 0 0px 0px 4px;
}

/**************************************************************************************

                                        HELPERS 

**************************************************************************************/
  
.textCenter {
    text-align: center
}
.animate {
    transition: 0.3s ease;
}
.pointer {
    cursor: pointer;
}
.relative {
    position: relative;
}
.textCenter {
    text-align: center;
}
.textLeft {
    text-align: left;
}
.textRight {
    text-align: right;
}
.x100 {
    width: 100%;
}
.active {
    border-bottom: 2px solid #7620FF;
    color: #7620FF;
}
.tag {
    padding: 5px 10px;
    margin: 10px 0;
}
.shadow {
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
    -moz-box-shadow: 0 0 11px rgba(237, 232, 237, 0.5)  ; 
}




/**************************************************************************************

                                        FLEXBOX 

**************************************************************************************/
  
.flex {
    display: flex;
}
.flexSpaceCenter {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}
.flexNullCenter {
    display: flex;
    align-items: center;
}
.flexColumn {
    flex-direction: column;
}
.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}
.flexSpaceNull {
    display: flex;
    justify-content: space-between;
}





/**************************************************************************************

                                        FONT SIZE

**************************************************************************************/


.font11 {
    font-size: 0.688rem;
}
.font12 {
    font-size: 0.75rem;
}
.font13 {
    font-size: 0.813rem;
}
.font14 {
    font-size: 0.875rem;
}
.font15 {
    font-size: 0.938rem;
}
.font18 {
    font-size: 1.125rem;
}
.font20 {
    font-size: 1.25rem;
}
.font25 {
    font-size: 1.563rem;
}
.font30 {
    font-size: 1.875rem;
}
.font40 {
    font-size: 2.5rem;
}
.font60 {
    font-size: 3.75rem;
    line-height: 4.5rem;
}


@media only screen and (max-width: 859px) {
    #panda3, #panda2, #panda1{
        display: none;
    }
    .font11 {
        font-size: 0.688rem;
    }
    .font12 {
        font-size: 0.688rem;
    }
    .font13 {
        font-size: 0.75rem;
    }
    .font14 {
        font-size: 0.813rem;
    }
    .font15 {
        font-size: 0.813rem;
    }
    .font18 {
        font-size: 0.938rem;
    }
    .font20 {
        font-size: 1.125rem;
    }
    .font25 {
        font-size: 1.25rem;
    }
    .font30 {
        font-size: 1.5rem;
    }
    .font40 {
        font-size: 1.875rem;
    }
    .font60 {
        font-size: 2.8rem;
    }
}


@media only screen and (max-width: 659px) {
    .sideNav{
        display: none !important;
    }
    #panda6{
        display: none;
    }
    #leftSideContainer{
        width: 95% !important;
    }
   
}

@media only screen and (max-width: 459px) {
    .sideNav{
        display: none !important;
    }
    .custom-tooltip:nth-child(2) .tooltiptext{
        left: -15px;
    }
    .custom-tooltip:nth-child(3) .tooltiptext{
        left: -100px;
    }
    .nav-tabs .nav-item{
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
    }
    #panda6{
        display: none;
    }
    #leftSideContainer{
        width: 95% !important;
    }
  
}
#m{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
#notFound{
    position: absolute;
    top: 48%;
    left: 45%;
    font-size: 20px;
    text-shadow: 2px 2px 15px blue;
    font-weight: 700;
    animation: shake-animation 2s cubic-bezier(.36,.07,.19,.97) both infinite;
}
@keyframes shake-animation {
    10%, 90% {
        transform: translate3d(-15px, 0, 0);
    }
    20%, 80% {
        transform: translate3d(160px, 0, 0);
    }
    30%, 50%, 70% {
        transform: translate3d(-40px, 0, 0);
    }
    40%, 60% {
        transform: translate3d(40px, 0, 0);
    }
}
#leftSideContainer small{
    color: black;
    font-size: 13px;
    margin: 0;
}
#leftSideContainer h1{
    margin-top: -5px;
    text-transform: uppercase;
}

#leftLine{
    position: absolute;
    left: -50px;
    color: black;
    /* width: 100%; */
    display: flex;
}

#leftLine .line{
    width: 0.5px;
    height: 350px;
    background: #161616;
    left: 0;
}

#leftLine span{
    position: absolute;
    bottom: -80px;
    transform: rotate(-90deg);
    width: 200px;
    right: -100px;
}
#leftSideContainer{
    width: 90%;
    margin: auto;
    text-align: left;
    /* max-width: 600px; */
    margin-left: 0;
}

#mintBG {
    position:fixed;
    left:0px;
    bottom: 0px;
    width:100%;
    height:100%;
    text-align:center;
    margin:0px;
    padding:0px;
    background-color:#C0DEED;
    z-index: 5;
      width: 100%;
      height: 100px;
      animation: awan-animasi 10s linear infinite;
      -ms-animation: awan-animasi 10s linear infinite;
      -moz-animation: awan-animasi 10s linear infinite;
      -webkit-animation: awan-animasi 10s linear infinite;
}

@keyframes awan-animasi {
    from {
    background-position: 0 0;
    }

    to {
    background-position: 100% 0;
    };
}

@-webkit-keyframes awan-animasi {
    from {
    background-position: 0 0;
    }

    to {
    background-position: 100% 0;
    };
}

@-ms-keyframes awan-animasi {
    from {
    background-position: 0 0;
    }

    to {
    background-position: 100% 0;
    };
}

@-moz-keyframes awan-animasi {
    from {
    background-position: 0 0;
    }

    to {
    background-position: 100% 0;
    };
}







.project-title{
    font-size: 3rem !important;
    font-weight: 900;
    color: black !important;
}


/* leaf animations */

#leaves {    
    position: fixed;
    top: -220px;
    right:-50px;
    width: 100%;
    text-align: right;
    transform: rotate(15deg);
}

#leaves i {
    display: inline-block;
    width: 200px;
    height: 150px;
    background: linear-gradient(to bottom right, #309900, #005600);
    transform: skew(20deg);
    border-radius: 5% 40% 70%;
    box-shadow: inset 0px 0px 1px #222;
    border: 1px solid #333;
    z-index: 1;
    animation: falling 5s 0s infinite;
}

#leaves i:nth-of-type(2n) { animation: falling2 5s 0s infinite; }
#leaves i:nth-of-type(3n) { animation: falling3 5s 0s infinite; }

#leaves i:before {
  position: absolute;
  content: '';
  top: 117px;
  right: 9px;
  height: 27px;
  width: 32px;
  transform: rotate(49deg);
  border-radius: 0% 15% 15% 0%;
  border-top: 1px solid #222;
  border-bottom: 1px solid #222;
  border-left: 0px solid #222;
  border-right: 1px solid #222;
  background: linear-gradient(to right, rgba(0,100,0,1), #005600);
  z-index: 1;
}

#leaves i:after {
  content: '';
  height: 125px;
  width: 10px;
  background: linear-gradient(to right, rgba(0,0,0,.15), rgba(0,0,0,0));
  display: block;
  transform: rotate(125deg);
  position: absolute;
  left: 85px;
  border-radius:50%;
}


#leaves i:nth-of-type(n)    { height:23px; width:30px; }
#leaves i:nth-of-type(n):before { width:7px; height:5px; top:17px; right:1px; }
#leaves i:nth-of-type(n):after { width:2px; height:17px; left: 12px; top:0px; }

#leaves i:nth-of-type(2n+1)    { height:11px; width:16px; }
#leaves i:nth-of-type(2n+1):before { width:4px; height:3px; top:7px; right:0px; }
#leaves i:nth-of-type(2n+1):after { width:2px; height:6px; left: 5px; top:1px; }

#leaves i:nth-of-type(3n+2)  { height:17px; width:23px; }
#leaves i:nth-of-type(3n+2):before  { height:4px; width:4px; top:12px; right:1px; }
#leaves i:nth-of-type(3n+2):after  { height:10px; width:2px; top:1px; left:8px; }

#leaves i:nth-of-type(n)   { animation-delay: 1.9s;}
#leaves i:nth-of-type(2n)  { animation-delay: 3.9s;}
#leaves i:nth-of-type(3n)  { animation-delay: 2.3s;}
#leaves i:nth-of-type(4n)  { animation-delay: 4.4s;}
#leaves i:nth-of-type(5n)  { animation-delay: 5s;  }
#leaves i:nth-of-type(6n)  { animation-delay: 3.5s;}
#leaves i:nth-of-type(7n)  { animation-delay: 2.8s;}
#leaves i:nth-of-type(8n)  { animation-delay: 1.5s;}
#leaves i:nth-of-type(9n)  { animation-delay: 3.3s;}
#leaves i:nth-of-type(10n) { animation-delay: 2.5s;}
#leaves i:nth-of-type(11n) { animation-delay: 1.2s;}
#leaves i:nth-of-type(12n) { animation-delay: 4.1s;}
#leaves i:nth-of-type(13n) { animation-delay: 1s;  }
#leaves i:nth-of-type(14n) { animation-delay: 4.7s;}
#leaves i:nth-of-type(15n) { animation-delay: 3s;  }

#leaves i:nth-of-type(n)    { background: linear-gradient(to bottom right, #309900, #005600); }
#leaves i:nth-of-type(2n+2)  { background: linear-gradient(to bottom right, #5e9900, #2b5600); }
#leaves i:nth-of-type(4n+1)  { background: linear-gradient(to bottom right, #990, #564500); }

#leaves i:nth-of-type(n)    { opacity: .7;}
#leaves i:nth-of-type(3n+1)  { opacity: .5;}
#leaves i:nth-of-type(3n+2)  { opacity: .3;}

#leaves i:nth-of-type(n)    {transform: rotate(180deg);}


#leaves i:nth-of-type(n) { animation-timing-function:ease-in-out;}

@keyframes falling {
    
    0% {
        transform:
            translate3d(300,0,0)
            rotate(0deg);
    }
    
    100% {
        transform:
            translate3d(-350px,700px,0)
            rotate(90deg);
        opacity: 0;
    }
}

@keyframes falling3 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(-20deg);
    }
    
    100% {
        transform:
            translate3d(-230px,640px,0)
            rotate(-70deg);
        opacity: 0;
    }
}

@keyframes falling2 {
     0% {
        transform:
            translate3d(0,0,0)
            rotate(90deg);
    }
    
    100% {
        transform:
            translate3d(-400px,680px,0)
            rotate(0deg);
        opacity: 0;
    }
}
.progressTitle h2{
    font-size: 16px;
}
.progressTitle{
    display: flex;
    justify-content: space-between;
}
.progressFooter{
    text-align: right;
    font-size: 14px;
}
#bottomSpan {
    display: flex;
    margin-bottom: 30px;
}.spanItem {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
    background: hsl(0deg 0% 100%);
    color: #323232;
    border: 3px solid #000000;
    flex: 1 1;
}
#s2{
    POSITION: ABSOLUTE;
    RIGHT: 40PX;
    MARGIN-TOP: 5PX;
}
#rightSide{
    position: sticky;
}
.progress .progress-bar {
    display: block;
    height: 100%;
    background-color: #919191;
    background-size: 300% 100%;
    -webkit-animation: progress-animation 2s linear infinite;
            animation: progress-animation 2s linear infinite;
  }
  
  #di img, #roadmapContent img{
    width: 100%;
  }
#mint-bar{
    BORDER-TOP: 2px solid black;
    position: relative;
    /* bottom: 0; */
    /* width: 100vw; */
    /* height: 90px; */
    /* box-shadow: 5px 5px 20px #130c035d; */
    /* left: 0; */
    /* right: 0; */
    /* z-index: 100; */
    /* margin: auto; */
    padding: 8px 00px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -7px;

}
.progress-container{
    position: relative;
    max-width: 100%;
    padding-top: 15px;
    color: black;
}
.progress-container{
    font-weight: 900 !important;
}
.mint-goal-icon p{
    position: absolute;
    width: 500px;
    top: 35px;
}
.mint-goal-icon{
    position: absolute;
    color: white !important;
    left: 0;
    font-size: 13px;
    right: 0;
    margin: auto;
    text-align: center;
    width: 3px;
    height:30px;
    background: #cf0a0a;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    border-bottom-right-radius: 0%;
    border-bottom-left-radius: 0%;
    transform: rotate(0deg);
    bottom: 15px;
}
@keyframes b-animation {
    0%    {transform:scale(1);}
    25%   {transform:scale(1.05);}
    40%   {transform:scale(0.94);}
    75%   {transform:scale(1.08);}
    100%  {transform:scale(1);}
}


.mint-goal-icon .tooltiptext{
    bottom: 100% !important;
    top: auto !important;
}

.custom-topnav{
    justify-content: flex-end;
    margin-top: 8px;
}
.signOutButton{
    display: block;
    padding: 3px 10px;
    background: #0e0e0e;
    border-radius: 0px;
    font-size: 11px;
}

.signout-section{
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 8px 15px;
    color: black;
    border-radius: 0;
}